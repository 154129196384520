import * as React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ShutterstockPhotoInternationalStudents from '../images/uploads/International_Students_shutterstock_655989763.jpg';

const InternationalStudents = () => {
  return (
    <Layout pageSlug="international-students">
      <SEO title="International Students" />
      <div className="row bg-grey bg-marque">
        <section className="row header">
          <div className="container">
            <div className="row content">
              <div className="col l24 m24 s24">
                <div className="box bs header-full-image">
                  <div className="page-heading">
                    <h3>International Students</h3>
                  </div>
                  <div className="inner-margin-top-minus-60">
                    {
                      <img
                        src={ShutterstockPhotoInternationalStudents}
                        alt="International Students"
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="row padding-bottom-30">
          <div className="container">
            <div className="row">
              <div className="col l16 m16 s24">
                <div className="box bs">
                  <h2>Helping you adapt.</h2>
                </div>
              </div>
            </div>
            <div className="row padding-bottom-10">
              <div className="col l12 m16 s24 offset-l2 offset-m2 offset-s0">
                <div className="box bs content-block">
                  <p>
                    Do you need to pay tuition fees, living or accommodation
                    expenses in another country? With our service you can pay in
                    your own currency via bank transfer or credit/debit card and
                    get better rates and service. Register for free and use our
                    secure payments service to send your money worldwide. Start
                    saving today.
                  </p>
                  <p>
                    All transactions take place via our FCA registered and
                    regulated partner for your financial security.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="row bg-purple padding-top-40 padding-bottom-60">
          <div className="container">
            <div className="row">
              <div className="col l24 m24 s24">
                <div className="box bs">
                  <h3>How can we help?</h3>

                  <div className="btn-inline">
                    <Link
                      className="btn btn-primary animated-arrow"
                      to="/contact-us"
                    >
                      <span className="the-arrow -left">
                        <span className="shaft"></span>
                      </span>
                      <span className="main">
                        <span className="text"> Enquire Now </span>
                        <span className="the-arrow -right">
                          <span className="shaft"></span>
                        </span>
                      </span>
                    </Link>
                    <Link className="btn btn-primary animated-arrow" to="/">
                      <span className="the-arrow -left">
                        <span className="shaft"></span>
                      </span>
                      <span className="main">
                        <span className="text"> Register Company </span>
                        <span className="the-arrow -right">
                          <span className="shaft"></span>
                        </span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default InternationalStudents;
